import { shallowRef } from 'vue-demi';
import { useAxiosResponseInterceptor } from '@/api';
import { redirectTo, lightspeedState } from '@/scaffolding/tko-lightspeed';

const hasSetFailIncrement = shallowRef(false);

export default function useErrorHandler() {
  useAxiosResponseInterceptor(null, (error) => {
    const url = error.request?.url || '';

    const isIntegrationsRequest = url.includes('integrations/');
    const isChatRequest = url.includes('chat/');
    const isSpacesRequest = url.includes('spaces/');

    // Ignore failed spaces requests.
    if (isSpacesRequest || isChatRequest || isIntegrationsRequest) {
      return Promise.reject(error);
    }

    // Ignore cached responses
    if (error.config?.cache === 'only-if-cached') {
      return Promise.reject(error);
    }

    if (error.response?.status === 401) {
      if (error.request?.suppressLoginModal) {
        return Promise.reject(error);
      }

      // If the TKO modal is ready, use it.
      if (window.app && !window.app.isShowingLoginModal()) {
        window.app.showLoginModal();
        return Promise.reject(error);
      }

      // Redirect to launchpad if TKO modal isn't available
      const fails = +sessionStorage.getItem('projects-auth-fails') || 0;

      if (fails >= 3) {
        sessionStorage.removeItem('projects-auth-fails');
        console.warn(`projects-auth-fails (comp), fallback to launchpad`, error);
        redirectTo('/launchpad/logout/projects?r=authFail');
      } else {
        if (!hasSetFailIncrement.value) {
          sessionStorage.setItem('projects-auth-fails', fails + 1);
          hasSetFailIncrement.value = true;
        }
        // lightspeedState().parentHref is a ko.observable to leverage the gloabl state.
        console.info('useAppErrorHandler - redirecting to login');
        redirectTo(`/launchpad/login/projects?continue=${encodeURIComponent(lightspeedState().parentHref)}`);
      }
    }
    return Promise.reject(error);
  });
}
