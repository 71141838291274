import { mapValues } from 'lodash-es';

// Note that for practical purposes, dynamic imports should be defined using literal strings,
// so code-splitting can work

// Routed Vue components should have a bundle name specified along with the import.
// If not they will be packaged with the main app and TKO bundle and not have live reload enabled.

const components = {
  'app-layout': () => import(/* webpackChunkName: "hybrid" */ '@sections/AppLayout'),
  'project-page': () => import(/* webpackChunkName: "project" */ '@pages/Project'),
  'tko-component': () => import(/* webpackChunkName: "hybrid" */ '@sections/TkoComponent'),
  'json-to-project': () => import(/* webpackChunkName: "jsonToProject" */ '@sections/JsonToProject'),
  'sample-projects-container': () =>
    import(/* webpackChunkName: "sampleProjects" */ '@sections/SampleProjectsContainer'),
  'onboarding-project-container': () =>
    import(/* webpackChunkName: "OnboardingProjectContainer" */ '@sections/OnboardingProjectContainer'),
  cancellation: () => import(/* webpackChunkName: "cancellation" */ '@sections/Cancellation'),
  trialstarted: () => import(/* webpackChunkName: "cancellation" */ '@sections/TrialStarted'),
  'planning-page': () => import(/* webpackChunkName: "planning" */ '@pages/Planning'),
  'planning-schedule': () => import(/* webpackChunkName: "schedule" */ '@sections/PlanningSchedule'),
  'projects-schedule': () => import(/* webpackChunkName: "schedule" */ '@sections/ProjectsSchedule'),
  'people-schedule': () => import(/* webpackChunkName: "schedule" */ '@sections/PeopleSchedule'),
  'planning-workload': () => import(/* webpackChunkName: "workload" */ '@pages/Planning/Workload/Workload.vue'),
  'reports-page': () => import(/* webpackChunkName: "reports" */ '@pages/Reports'),
  'planned-vs-actual-milestones': () =>
    import(/* webpackChunkName: "reports" */ '@pages/Reports/PlannedVsActualMilestonesReport'),
  'planned-vs-actual-tasks': () =>
    import(/* webpackChunkName: "reports" */ '@pages/Reports/PlannedVsActualTasksReport'),
  'utilization-report': () => import(/* webpackChunkName: "reports" */ '@pages/Reports/UtilizationReport'),
  playground: () => import(/* webpackChunkName: "jsonToProject" */ '@sections/Playground'),
  migrate: () => import(/* webpackChunkName: "jsonToProject" */ '@sections/Migrate'),
  emailgateway: () => import(/* webpackChunkName: "jsonToProject" */ '@sections/EmailGateway'),
  growvember: () => import(/* webpackChunkName: "growvember" */ '@sections/Growvember'),
  sdrcall: () => import(/* webpackChunkName: "sdrCall" */ '@sections/SDRCall'),
  'page-next': () => import(/* webpackChunkName: "hybrid" */ '@pages/Next/Next.vue'),
};

const lookup = (val) => (typeof val === 'string' ? components[val] : val);

export default (route) => {
  const output = { ...route };
  if (output.component) {
    output.component = lookup(output.component);
  } else if (output.components) {
    output.components = mapValues(output.components, lookup);
  }
  return output;
};
